import flatpickr from "flatpickr";

document.addEventListener("DOMContentLoaded", function(event) {
	document.querySelectorAll('.datetimepicker').flatpickr({enableTime: true});
	document.querySelectorAll('.datepicker').flatpickr();
	document.querySelectorAll('.timepicker').flatpickr({
		dateFormat: "H:i",
		enableTime: true,
		noCalendar: true
	});
});

